<template>
  <div id="index">
    <div class="carousel-container">
      <carousel></carousel>
    </div>
    <div class="slogan">
      <div class="slogan-container wei-container">
        <div class="slogan-item" v-for="(item, index) in slogan" :key="index">
          <img
            :src="require('../../assets/images/icon' + (index + 1) + '.jpg')"
            alt=""
          />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="product ">
      <div class="product-container ">
        <Title :title="product"></Title>
      </div>
      <div class="wei-container">
        <div class="type flex-row ">
          <div
            class="type-item pointer"
            v-for="(item, index) in typeList"
            :key="index"
            @click="changeType(item)"
            :class="{ active: activeType == item.id }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="part flex-row ">
          <div
            class="part-item pointer"
            v-for="(item, index) in productList"
            :key="index"
            @click="toViewDetail(item.id)"
            @mouseenter=""
          >
            <div
              class="img_info"
              @mouseenter="shadowId = item.id"
              @mouseleave="shadowId = 0"
            >
              <img :src="imgUploadUrl + item.img" alt="" />
              <div class="shadow" v-show="shadowId === item.id">
                {{ product.lookText }}
              </div>
            </div>
            <div class="product_info">
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <button class="pointer" @click="toMore">{{ product.more }}</button>
      </div>
    </div>
    <Introduce></Introduce>
    <div class="certificate_container">
      <Certificate></Certificate>
    </div>
    <Article></Article>
  </div>
</template>
<script>
import carousel from "@/components/Carousel/Index";
import Title from "@/components/title";
import Certificate from "@/components/Certificate";
import Introduce from "./introduce";
import Article from "./article";
import { mapGetters } from "vuex";
import { queryIndexProduct } from "@/api/";
import { scrollTo } from '@/utils/scroll-to'
export default {
  name: "Index",
  components: {
    carousel,
    Title,
    Certificate,
    Introduce,
    Article,
  },
  data() {
    return {
      slogan: {},
      product: {},
      typeList: [],
      activeType: 0,
      productList: [],
      shadowId: 0,
    };
  },
  created() {
    this.slogan = this.$t("index.slogan");
    this.product = this.$t("product");
    this.getProductList();
  },
  computed: {
    ...mapGetters(["imgUploadUrl"]),
  },
  mounted() {
    this.locale = this.$i18n.locale;
  },
  methods: {
    getProductList() {
      queryIndexProduct().then((res) => {
        this.typeList = res.data;
        this.changeType(this.typeList[0]);
      });
    },
    changeType(item) {
      this.activeType = item.id;
      this.productList = item.childrens;
      this.productList.push({});
      this.productList.pop();
    },
    toMore() {
      this.$router.push("/product");
      scrollTo(0,800)
    },
    toViewDetail(id) {
           this.$router.push(`/product?first=${this.activeType}&second=${id}`);
           scrollTo(0,800)
    },
    // mouseover(index) {
    //   this.hoverIndex = index;
    // },
  },
};
</script>
<style lang="scss" scoped>
#index {
  width: 100%;
  // min-width: 1200px;
  height: auto;
  .carousel-container {
    width: 100%;
  }
  .slogan {
    padding: 120px 0;
    background-color: #fff;
    .slogan-container {
      display: flex;
    }
    .slogan-item {
      flex: 1;
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
      }
      .name {
        font-size: 16px;
        color: #19222f;
      }
    }
  }
  .product {
    background: #f7f8fa;
    .type {
      .type-item {
        text-align: left;
        font-size: 16px;
        height: 80px;
        padding: 0 30px;
        &:hover {
          color: #41cfad;
        }
        &.active {
          font-weight: bold;
          color: #41cfad;
          position: relative;
          &::after {
            display: table;
            position: absolute;
            content: "";
            left: 50%;
            width: 36px;
            height: 4px;
            background: #41cfad;
            top: 30px;
            transform: translateX(-60%);
          }
        }
      }
    }
    .part {
      .part-item {
        width: 295px;
        background: #fff;
        margin-right: 40px;
        padding-bottom: 25px;

        &:nth-child(4) {
          margin-right: 0;
        }
        .img_info {
          width: 100%;
          height: 295px;
          background: #000000;
          opacity: 0.7;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .shadow {
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.7);
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            line-height: 295px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .product_info {
          padding: 0 20px;
          .name {
            font-size: 18px;
            text-align: center;
            margin-top: 19px;
          }
          .summary {
            font-size: 14px;
            color: #aaaaaa;
            text-align: left;
            height: 40px;
          }
        }
      }
    }
    button {
      width: 120px;
      height: 36px;
      background: #19222f;
      font-size: 14px;
      color: #ffffff;
      margin: 80px 0 150px 0;
      border:0;
      &:hover {
        background: rgba($color: #19222f, $alpha: 0.9);
      }
    }
  }
  .certificate_container {
    background: #fff;
  }
}
</style>
