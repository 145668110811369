<template>
  <div class="index_introduce">
    <!-- <img src="../../assets/images/intro_bg.png" alt="" class="intro_bg"> -->
    <div class="intro-container wei-container">
      <div class="info-container">
        <Title :title="introduce"></Title>
        <div class="summary">
          {{ companyInfo.gsjs }}
        </div>
      </div>
      <Mission></Mission>
    </div>
  </div>
</template>
<script>
import Title from "@/components/title";
import Mission from "@/components/Mission";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "introduce",
  components: {
    Title,
    Mission,
  },
  data() {
    return {
      introduce: {},
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  created() {
    this.introduce = this.$t("introduce");
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.index_introduce {
  position: relative;
  height: 1306px;
  background: url("../../assets/images/intro_bg.png") no-repeat;
  background-color: #f7f8fa;
  .intro-container {
    padding: 0;
    position: absolute;
    top: 400px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f7f8fa;
    .info-container {
      padding-bottom: 104px;
      background-color: #fff;
      padding: 0 70px 118px 70px;
      .summary {
        font-family: Adobe Heiti Std;
        color: #636d7c;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
}
</style>
<style lang="scss">
.index_introduce {
  .intro-container {
    .cname {
      margin-top: 100px;
    }
  }
}
</style>
