<!--
 * @Author: your name
 * @Date: 2020-06-02 10:07:02
 * @LastEditTime: 2020-06-10 10:14:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JiNuo-web\src\components\Carousel\Index.vue
-->
<template>
  <div id="slider" style="margin:0;padding:0">
    <div class="border_box" style="margin:0;padding:0">
      <div
        class="skitter box_skitter box_skitter_large"
        style="margin:0;padding:0"
      >
        <ul>
          <li v-for="(item, i) in bannerList" :key="i" >
            <a :href="item.url" target="_blank">
              <img :src="item.img" :class="styleList[i%3]"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import banner_1 from "@/assets/img/banner.png";
import { queryIndexBanner } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  name: "Carousel",
  data() {
    return {
      bannerList: [],
      styleList: ["cube", "paralell", "cubeRandom"],
    };
  },
  created() {
    this.getIndexBanner();
  },
    computed: {
    ...mapGetters(["imgUploadUrl"]),
  },
  mounted() {
  },
  methods: {
    getIndexBanner() {
      queryIndexBanner().then(res=> {
         res.data.forEach(item=>{
           item.img=this.imgUploadUrl+item.img
         })
     
        this.bannerList = res.data;
        this.$nextTick(() => {
          window.jQuery(".box_skitter_large").skitter({
            label: false,
            numbers: false,
            theme: "square",
            dots: false,
            // show_randomly: true,
            // enable_navigation_keys: true,
            navigation: true,
            // controls:true
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#slider {
  width: 100%;
}
</style>
<style lang="scss">
.skitter {
  max-width: 100%;
}
.container_skitter {
  width: 100% !important;
}
.skitter-square .play_pause_button,
.skitter-square .focus_button,
.skitter-square .prev_button {
  background: url(../../assets/img/index/left.png) no-repeat;
  background-size: 50px 50px;
  top: 50%;
  margin-top: -30px;
  left: 230px;
}
.skitter-square .next_button {
  background: url(../../assets/img/index/right.png) no-repeat;
  background-size: 50px 50px;
  top: 50%;
  margin-top: -30px;
  right: 230px;
}
// .skitter-square .play_pause_button, .skitter-square .next_button, .skitter-square .focus_button, .skitter-square .next_button{
//   // background: url(../../assets/img/skitter/sprite-square.png) no-repeat;
//   // background-size: 110px auto;
// }
</style>
