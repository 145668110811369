<template>
  <div class="index_article">
    <Title :title="news"></Title>
    <div class="article_container flex-row wei-container">
      <div
        class="article_item pointer"
        v-for="(item, index) in newsList"
        :key="index"
        @click="toViewDetail(item.id)"
      >
        <div class="img_info pointer">
          <img :src="imgUploadUrl + item.img" alt="" />
        </div>
        <div class="product_info">
          <div class="name">{{ item.title }}</div>
          <div class="summary ellipsis-2">{{ item.synopsis }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "@/components/title";
import { mapGetters, mapMutations } from "vuex";
import { queryIndexArticle } from "@/api/";
import { scrollTo } from '@/utils/scroll-to'
export default {
  name: "index_article",
  components: {
    Title,
  },
  data() {
    return {
      newsList: [],
      news: {},
    };
  },
  created() {
    this.news = this.$t("news");
    this.getQueryData();
  },
    computed: {
    ...mapGetters(["imgUploadUrl"]),
  },
  mounted() {},
  methods: {
    getQueryData() {
      queryIndexArticle().then((res) => {
        this.newsList = res.data;
      });
    },
    toViewDetail(id){
      this.$router.push({
        path:'/news_detail?id='+id
      })
      scrollTo(0,800)
    }
  },
};
</script>
<style lang="scss" scoped>
.index_article {
  padding-bottom: 150px;
  background: #F7F8FA;
  .article_container{
  justify-content: space-around;
 .article_item {
    width: 300px;
    img {
      width: 100%;
      height: 170px;
    }
    .name {
      font-size: 18px;
      color: #19222f;
      text-align: left;
      margin:18px 0;
    }
    .summary {
      font-size: 14px;
      color: #aaaaaa;
    }
  }
  }
 
}
</style>
